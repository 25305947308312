<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.MERCADO_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.MERCADO_CHANNEL.DESC')"
    />

    <!-- Authentication Button -->
    <div v-if="!isAuthenticated">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.CHANNEL_NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.appId.$error }">
          {{ $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.APP_ID.LABEL') }}
          <input
            v-model.trim="appId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.APP_ID.PLACEHOLDER')
            "
            @blur="$v.appId.$touch"
          />
          <span v-if="$v.appId.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.MERCADO_CHANNEL.APP_ID.ERROR') }}
          </span>
        </label>
      </div>

      <button class="button-auth" @click="authenticateWithMercado">
        Authenticate with Mercado
      </button>
    </div>

    <!-- Form to Create Channel (visible only after authentication) -->
    <form
      v-if="isAuthenticated"
      class="mx-0 flex flex-wrap"
      @submit.prevent="createChannel()"
    >
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.MERCADO_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
/* global axios */
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      appId: '', // Collect APP ID
      accessToken: '', // Store the access token here
      isAuthenticated: false, // Flag to track authentication state
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    appId: { required }, // Validate APP ID
  },
  mounted() {
    // Check if there is a 'code' in the URL, and if so, exchange it for an access token
    const code = new URLSearchParams(window.location.search).get('code');
    if (code) {
      this.getAccessToken(code);
    }
  },
  methods: {
    authenticateWithMercado() {
      const clientId = process.env.VUE_APP_MERCADO_CLIENT_ID;
      const redirectUri = process.env.VUE_APP_MERCADO_REDIRECT_URI; // Redirect URI
      const authUrl = `https://auth.mercadolibre.com.uy/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
      window.location.href = authUrl; // Redirect user to Mercado authentication page
    },
    async getAccessToken(code) {
    try {
      // Make an API call to exchange the code for an access token
      const response = await axios.post('https://api.mercadolibre.com/oauth/token', {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_MERCADO_CLIENT_ID,
        client_secret: process.env.VUE_APP_MERCADO_CLIENT_SECRET,
        code: code,
        redirect_uri: process.env.VUE_APP_MERCADO_REDIRECT_URI
      });
      this.accessToken = response.data.access_token;
      this.isAuthenticated = true;
      this.showAlert('Successfully authenticated with Mercado');
    } catch (error) {
      this.showAlert('Failed to authenticate with Mercado.');
      console.error(error);
    }
    },
    async createChannel() {
      this.$v.$touch(); // Validate form fields
      if (this.$v.$invalid) {
        return;
      }

      if (!this.isAuthenticated) {
        this.showAlert('Please authenticate with Mercado first.');
        return;
      }

      try {
        // Use the access token to create the channel
        const response = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          accessToken: this.accessToken,
          appId: this.appId,
        });

        // Redirect to the new channel
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: response.data.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.MERCADO_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped>
.button-auth {
  background-color: #0284c7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-auth:hover {
  background-color: #0369a1;
}
</style>
